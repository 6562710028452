<script setup lang="ts">
import { useLayout } from './composables/layout';
import {computed, onMounted, ref, watch} from 'vue';
import AppFooter from './AppFooter.vue';
import AppSidebar from './AppSidebar.vue';
import AppTopbar from './AppTopbar.vue';
import { useCookies } from 'vue3-cookies';
import AppTopNavigation from '@/PageLayout/AppTopNavigation.vue';

const { layoutConfig, layoutState, isSidebarActive, resetMenu, loadDarkMode } = useLayout();

const outsideClickListener = ref(null);

watch(isSidebarActive, (newVal) => {
    if (newVal) {
        bindOutsideClickListener();
    } else {
        unbindOutsideClickListener();
    }
});

const containerClass = computed(() => {
    return {
        'layout-overlay': layoutConfig.menuMode === 'overlay',
        'layout-static': layoutConfig.menuMode === 'static',
        'layout-static-inactive': layoutState.staticMenuDesktopInactive && layoutConfig.menuMode === 'static',
        'layout-overlay-active': layoutState.overlayMenuActive,
        'layout-mobile-active': layoutState.staticMenuMobileActive
    };
});

function bindOutsideClickListener() {
    if (!outsideClickListener.value) {
        outsideClickListener.value = (event) => {
            if (isOutsideClicked(event)) {
                resetMenu();
            }
        };
        document.addEventListener('click', outsideClickListener.value);
    }
}

function unbindOutsideClickListener() {
    if (outsideClickListener.value) {
        document.removeEventListener('click', outsideClickListener);
        outsideClickListener.value = null;
    }
}

function isOutsideClicked(event) {
    const sidebarEl = document.querySelector('.layout-sidebar');
    const topbarEl = document.querySelector('.layout-menu-button');

    return !(sidebarEl.isSameNode(event.target) || sidebarEl.contains(event.target) || topbarEl.isSameNode(event.target) || topbarEl.contains(event.target));
}

onMounted(() => {
    loadDarkMode();
});



const { cookies } = useCookies();

onMounted(() => {
    // Check if cookie set
    if(!cookies.get('welcome-message-shown')) {
        showWelcomeMessage.value = true;
        cookies.set('welcome-message-shown', 'true', '399D');
    }
});

const showWelcomeMessage = ref<boolean>(false);

</script>

<template>
    <div class="layout-wrapper" :class="containerClass">
        <app-topbar></app-topbar>

        <div class="visible lg:invisible">
            <app-top-navigation :full-width="true"></app-top-navigation>
        </div>
        <app-sidebar></app-sidebar>

        <PrimeDialog v-model:visible="showWelcomeMessage" modal header="Swytch Tools v7" :style="{ width: '25rem' }">
            <span class="text-surface-500 dark:text-surface-400 mb-8 flex justify-center mt-2">
                <PrimeMessage severity="success">Hey good lookin'</PrimeMessage>
            </span>

            <span class="text-surface-500 dark:text-surface-400 block mb-8">
                You may notice some changes round here over the next few months.
            </span>
            <span class="text-surface-500 dark:text-surface-400 block mb-8">
                We're working to bring Swytch Tools up to a much higher standard, with faster and more reliable tools.
            </span>
            <span class="text-surface-500 dark:text-surface-400 block mb-8">
                We will be working through each page in turn, so expect to see some changes.
            </span>
            <span>
                <PrimeMessage severity="info">Message Toby if you regularly use a page that needs some love and care, so we can prioritise it.</PrimeMessage>
            </span>
            <div class="flex justify-end gap-2 mt-4">
                <PrimeButton type="button" label="Let's go!" @click="showWelcomeMessage = false"></PrimeButton>
            </div>
        </PrimeDialog>

        <div class="layout-main-container">
            <div class="layout-main">
                <slot></slot>
            </div>
            <app-footer></app-footer>
        </div>
        <div class="layout-mask animate-fadein"></div>
    </div>
    <PrimeToast />
    <PrimeConfirmDialog></PrimeConfirmDialog>
</template>
