<script setup lang="ts">
import {SearchResultProps} from '../../types/search';
import SearchResultCard from '../SearchResultCard.vue';
import {computed} from 'vue';
import StatusChip from '@/Components/Status/StatusChip.vue';

const props = defineProps<SearchResultProps<{
    id: number;
    warehouse: string;
    reference: string;
    status: string;
    unified_status: string;
    order_reference: string;
    dispatch_reference: string;
    customer_name: string;
    customer_email: string;
    customer_address: string;
    skus: {
        quantity: number;
        sku: string;
    }[]
}>>();

const subtitle = computed(() => {
    let subtitle = 'A return for ' + props.params.customer_name + ' (' + props.params.customer_email + ')';

    if(props.params.order_reference) {
        subtitle += ' from order ' + props.params.order_reference;
    }

    if(props.params.dispatch_reference) {
        if(props.params.order_reference) {
            subtitle += ' and';
        }
        subtitle += ' from dispatch ' + props.params.dispatch_reference;
    }

    return subtitle;
});

</script>

<template>
    <search-result-card
        :subtitle="subtitle"
        :url="$route('tools.returns.returns.show', {return: props.params.id})"
        type="Return"
        :relevancy="props.relevancy"
        :title="props.params.reference"
        icon="fa fa-undo"
        :compact="props.compact"
        colour="orange"
    >
        <template #tags>
            <StatusChip :status="props.params.status" :unified-status="props.params.unified_status"></StatusChip>

            <!--            <PrimeBadge class="mr-2" severity="success" v-if="props.params.compatible">Compatible</PrimeBadge>-->
            <!--            <PrimeBadge class="mr-2" severity="danger" v-if="props.params.compatible === false">Not Compatible</PrimeBadge>-->
            <!--            <PrimeBadge class="mr-2" severity="secondary" v-else>Unassessed</PrimeBadge>-->

        </template>

        <template #body>
            <div class="flex flex-col">
                <div class="flex flex-row">
                    <i class="fa fa-map-marker-alt mr-2"></i>
                    <span>{{ props.params.customer_address }}</span>
                </div>
                <div class="flex flex-row">
                    <i class="fa fa-warehouse mr-2"></i>
                    <span>Sending to {{ props.params.warehouse }}</span>
                </div>
                <PrimeDivider></PrimeDivider>
                <PrimeDataTable :value="props.params.skus" size="small">
                    <PrimeColumn field="quantity" header="Quantity"></PrimeColumn>
                    <PrimeColumn field="sku" header="SKU"></PrimeColumn>
                    <template #empty>No items in dispatch</template>
                </PrimeDataTable>
            </div>
        </template>

    </search-result-card>
</template>

<style scoped>

</style>