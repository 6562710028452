<script setup lang="ts">
import {SearchResultProps} from '../../types/search';
import SearchResultCard from '../SearchResultCard.vue';
import {computed} from 'vue';

const props = defineProps<SearchResultProps<{
    id: number;
    email: string;
    address: string;
    phone: string;
    name: string;
    order_count: number;
}>>();

const subtitle = computed(() => {
    return props.params.email;
});

const customerTitle = computed(() => {
    return props.params.name ?? 'Unknown Customer';
});

</script>

<template>
    <search-result-card
        :subtitle="subtitle"
        :url="$route('core.customers.show', {customer: props.params.id})"
        type="Customer"
        :title="customerTitle"
        :relevancy="props.relevancy"
        icon="fa fa-user"
        colour="black"
        :compact="props.compact"
    >
        <template #tags>
            <PrimeBadge class="mr-2" severity="secondary">{{ props.params.order_count }} orders</PrimeBadge>

        </template>

        <template #body>
            <div class="flex flex-col">
                <div class="flex flex-row items-center">
                    <i class="fa fa-map-marker-alt mr-2"></i>
                    <span>{{ props.params.address }}</span>
                </div>
                <div class="flex flex-row items-center">
                    <i class="fa fa-phone mr-2"></i>
                    <span>{{ props.params.phone }}</span>
                </div>
            </div>
        </template>

    </search-result-card>
</template>

<style scoped>

</style>