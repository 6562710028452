import {ref} from 'vue';
import {setting, settings} from '../../../../resources/js/Tools/settings/settings';
import {useToast} from 'primevue';

export const useFavouriteItem = () => {

    let toast = null;

    try {
        toast = useToast();
    } catch (error) {
        toast = null;
    }

    const loadItems = () => {
        settings.loadSettings([
            'favourited_items',
        ]);
    };

    const savingSettings = ref<boolean>(false);

    const favouriteItem = (item: string) => {
        if (!(setting.value.favourited_items ?? []).includes(item)) {
            setting.value.favourited_items.push(item);
            saveSettings();
            toast?.add({
                severity: 'success',
                summary: 'Item added to favourites',
                life: 3000,
            });
        }
    };

    const unfavouriteItem = (item: string) => {
        if ((setting.value.favourited_items ?? []).includes(item)) {
            setting.value.favourited_items.splice(setting.value.favourited_items.indexOf(item), 1);
            saveSettings();
            toast?.add({
                severity: 'success',
                summary: 'Item removed from favourites',
                life: 3000,
            });
        }
    };

    const saveSettings = () => {
        savingSettings.value = true;
        settings.setValues({
            favourited_items: setting.value.favourited_items,
        }, () => {
            savingSettings.value = false;
        });
    };

    loadItems();

    return {favouriteItem, unfavouriteItem, savingSettings};

};
