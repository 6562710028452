<script setup lang="ts">

import {computed} from 'vue';

const props = defineProps<{
    target: HTMLElement,
    tooltip: string,
}>();

const boundingBox = computed(() => {
    // console.log(props.target.getBoundingClientRect());
    const getBoundingBox = (element: HTMLElement) => {
        return element.getBoundingClientRect();
    };

    let t = props.target;
    while(t) {
        let bb = getBoundingBox(t);
        if(bb.height > 0 && bb.width > 0) {
            return bb;
        }
        t = t.parentElement;
    }

    return props.target.getBoundingClientRect();
});

const x = computed<number>(() => {
    const centerX = boundingBox.value.left + boundingBox.value.width / 2;

    // We need to subtract half the width of the target, which is 16px
    return centerX - 16;
});

const y = computed<number>(() => {
    const centerY = boundingBox.value.top + boundingBox.value.height / 2;

    // We need to subtract half the height of the target, which is 16px
    return centerY - 16;
});

</script>

<template>
    <div :style="{'top': y + 'px', 'left': x + 'px'}" class="fixed z-50 hide-help:hidden flex items-center justify-center overflow-visible" v-tooltip.bottom="props.tooltip">
        <i class="fa fa-circle-question !text-2xl text-black animate-radiate"></i>
    </div>
</template>

<style scoped>


@keyframes radiate {
    0% {
        box-shadow: 0 0 10px rgba(255, 255, 255, 0.5), 0 0 20px rgba(255, 255, 255, 0.5), 0 0 30px rgba(255, 255, 255, 0.5);
    }
    50% {
        box-shadow: 0 0 20px rgba(255, 255, 255, 0.7), 0 0 40px rgba(255, 255, 255, 0.7), 0 0 60px rgba(255, 255, 255, 0.7);
    }
    100% {
        box-shadow: 0 0 10px rgba(255, 255, 255, 0.5), 0 0 20px rgba(255, 255, 255, 0.5), 0 0 30px rgba(255, 255, 255, 0.5);
    }
}

.outer-border {
    border: 4px solid;
    border-image: linear-gradient(45deg, #ff4d5c, #ffbd37, #00d188) 1;
    border-radius: 50%;
    padding: 10px;
}

.inner-border {
    border: 2px solid;
    border-image: linear-gradient(45deg, #ff4d5c, #ffbd37, #00d188) 1;
    border-radius: 50%;
    padding: 10px;
}

.animate-radiate {
    animation: radiate 2s infinite;
    border-radius: 50%;
}
</style>