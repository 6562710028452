<script lang="ts" setup>
import {Link, usePage} from '@inertiajs/vue3';
import {computed} from 'vue';
import {MenuItem} from 'primevue/menuitem';
import {route} from 'ziggy-js';

const props = withDefaults(defineProps<{
    fullWidth: boolean;
}>(), {
    fullWidth: false
});

const page = usePage();

const sidebar = computed(() => {
    return page.props.topbar?.items ?? [];
});

const mainHeaders = computed<Array<MenuItem>>(() => {
    let headers: Array<MenuItem> = [];

    for(let item of sidebar.value) {
        if(item.visible) {
            headers.push({
                label: item.label,
                icon: item.icon,
                url: item.route ? route(item.route) : null,
                active: item.is_active_route
            });
        }
    }

    return headers;
});

</script>

<template>
    <div :class="{'layout-primary-navigation-bar': props.fullWidth}">
        <PrimeMenubar :model="mainHeaders" breakpoint="600px" pt:root:class="!border-none sm:!justify-center !justify-end" class="!text-primary">
            <template #item="{ item }">
                <Link v-ripple class="p-2 mx-2" :class="{'text-primary font-semibold': item.active}" v-if="item.url" :href="item.url">
                    <i v-if="item.icon" :class="'fa fa-' + item.icon + ' mr-2'"></i>
                    <span>{{ item.label }}</span>
                </Link>
            </template>
        </PrimeMenubar>
    </div>
</template>

<style scoped>
.p-menubar-item-content {
    color: var(--primary-color) !important;
}
</style>