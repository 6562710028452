<script lang="ts" setup>
import {useLayout} from '../../PageLayout/composables/layout';
import {onBeforeMount, ref, watch} from 'vue';
import {Link} from '@inertiajs/vue3';

const {layoutState, setActiveMenuItem, onMenuToggle} = useLayout();

const props = defineProps({
    item: {
        type: Object,
        default: () => ({})
    },
    index: {
        type: Number,
        default: 0
    },
    root: {
        type: Boolean,
        default: true
    },
    parentItemKey: {
        type: String,
        default: null
    }
});

const isActiveMenu = ref(false);
const itemKey = ref(null);

onBeforeMount(() => {
    itemKey.value = props.parentItemKey ? props.parentItemKey + '-' + props.index : String(props.index);

    const activeItem = layoutState.activeMenuItem;

    isActiveMenu.value = activeItem === itemKey.value || activeItem ? activeItem.startsWith(itemKey.value + '-') : props.item.is_active_route;
});

watch(
    () => layoutState.activeMenuItem,
    (newVal) => {
        isActiveMenu.value = newVal === itemKey.value || newVal.startsWith(itemKey.value + '-');
    }
);

function itemClick(event, item) {
    if (item.disabled) {
        event.preventDefault();
        return;
    }

    if ((item.to || item.url) && (layoutState.staticMenuMobileActive || layoutState.overlayMenuActive)) {
        onMenuToggle();
    }

    if (item.command) {
        item.command({originalEvent: event, item: item});
    }

    const foundItemKey = item.items ? (isActiveMenu.value ? props.parentItemKey : itemKey) : itemKey.value;

    setActiveMenuItem(foundItemKey);
}

const emit = defineEmits<{
    (event: 'onRightClick', value: { [key: string]: any }): void
}>();

const onRightClick = (event, item) => {
    emit('onRightClick', {event, item});
};

const childOnRightClick = ({event, item}) => {
    onRightClick(event, item);
};

</script>

<template>


    <li :class="{ 'layout-root-menuitem': root, 'active-menuitem': isActiveMenu }">
        <!-- Item is a root section -->
        <div v-if="root && item.visible !== false" class="layout-menuitem-root-text">{{ item.label }}</div>

        <!-- Item does not have a URL, so is a heading -->
        <a
            v-tooltip.right="item.description ?? null"
            v-if="(!item.route || item.items) && item.visible !== false"
            v-ripple
            href="#"
            tabindex="0" @click="itemClick($event, item)">
            <i :class="['fa', 'fa-' + item.icon, 'text-grey-800']" class="layout-menuitem-icon"></i>
            <span class="layout-menuitem-text">{{ item.label }}</span>
            <i v-if="item.items" class="fa fa-angle-down layout-submenu-toggler"></i>
        </a>

        <div v-if="item.route && !item.items && item.visible !== false"
             v-tooltip.right="item.description ?? null">
            <div v-if="index === 0" class="w-full">

            </div>
            <Link v-ripple
                  :class="[item.class, { 'active-route': item.is_active_route }]"
                  :href="item.route"
                  tabindex="0" @click="itemClick($event, item, index)"
                  @contextmenu="onRightClick($event, item)"
            >
                <i :class="['fa', 'fa-' + item.icon, 'text-grey-800']" class="layout-menuitem-icon"></i>
                <span class="layout-menuitem-text">{{ item.label }}</span>
            </Link>
        </div>

        <!-- Items to sit below a header -->
        <Transition v-if="item.items && item.visible !== false" name="layout-submenu">
            <ul v-show="root ? true : isActiveMenu" class="layout-submenu">
                <app-menu-item v-for="(child, i) in item.items" :key="child"
                               :index="i" :item="child" :parentItemKey="itemKey"
                               :root="false" @onRightClick="childOnRightClick"></app-menu-item>
            </ul>
        </Transition>
    </li>

</template>

<style lang="scss" scoped></style>
