<script setup lang="ts">
import {SearchResultProps} from '../../types/search';
import SearchResultCard from '../SearchResultCard.vue';
import {computed} from 'vue';
import StatusChip from '@/Components/Status/StatusChip.vue';

const props = defineProps<SearchResultProps<{
    id: number;
    reference: string;
    status: string;
    unified_status: string;
    dispatch_count: number;
    skus: {
        quantity: number;
        sku: string;
    }[],
    customer_name: string;
    customer_email: string;
    customer_address: string;
}>>();

const subtitle = computed(() => {
    return 'A requested dispatch for ' + props.params.customer_name + ' (' + props.params.customer_email + ')';
});

</script>

<template>
    <search-result-card
        :subtitle="subtitle"
        :url="$route('tools.dispatch-requests.show', {dispatch_request: props.params.id})"
        :relevancy="props.relevancy"
        type="Dispatch Request"
        :title="props.params.reference"
        icon="fa fa-truck-ramp-box"
        colour="brown"
        :compact="props.compact"
    >
        <template #tags>
            <PrimeBadge v-if="props.params.dispatch_count" severity="warn" class="mr-2">{{ props.params.dispatch_count }} Dispatch<span v-if="props.params.dispatch_count > 1">es</span></PrimeBadge>
            <StatusChip :status="props.params.status" :unified-status="props.params.unified_status"></StatusChip>
        </template>

        <template #body>
            <div class="flex flex-col">
                <div class="flex flex-row">
                    <i class="fa fa-map-marker-alt mr-2"></i>
                    <span>{{ props.params.customer_address }}</span>
                </div>

            </div>
        </template>

        <template #bodyRight>
            <PrimeDivider></PrimeDivider>
            <PrimeDataTable :value="props.params.skus" size="small">
                <PrimeColumn field="quantity" header="Quantity"></PrimeColumn>
                <PrimeColumn field="sku" header="SKU"></PrimeColumn>
                <template #empty>No items in dispatch request</template>
            </PrimeDataTable>
        </template>

    </search-result-card>
</template>

<style scoped>

</style>