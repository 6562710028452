import {h, render} from 'vue';
import Help from '@/Components/Help/Help.vue';

function showHelp(el, binding) {
    const helpComponent = h(Help, {
        target: el,
        tooltip: binding.value,
    });

    render(helpComponent, el);
}

export const vHelp = {
    mounted(el, binding) {
        const isOnce = binding.modifiers.once ?? false;

        const alreadyShown = window['helpTipsShown'] || [];

        if (isOnce && !alreadyShown.includes(binding.value)) {
            alreadyShown.push(binding.value);
            window['helpTipsShown'] = alreadyShown;
            showHelp(el, binding);
        } else if(!isOnce) {
            showHelp(el, binding);
        }
    },
};