import {computed} from 'vue';
import {usePage} from '@inertiajs/vue3';

export function useAuth() {

    const page = usePage();

    const user = computed(() => page.props.auth.user);

    const id = computed(() => user.value?.id);

    const initials = computed(() => {
        return user.value?.name.split(' ').map((n: string) => n[0]).join('');
    });

    const name = computed(() => {
        return user.value?.name;
    });

    const email = computed(() => {
        return user.value?.email;
    });

    return {id, initials, name, email};
}
