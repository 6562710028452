<script setup lang="ts">
const props = withDefaults(defineProps<{
    url: string;
    type: string;
    title: string;
    icon: string;
    colour: string;
    subtitle?: string|null;
    disabled?: boolean;
    relevancy: number;
    compact?: boolean;
}>(), {
    subtitle: null,
    disabled: false,
    compact: false
});

</script>

<template>
    <PrimeCard>
        <template #title>
            <div class="flex flex-row w-full justify-between items-center space-x-2">
                <Link :href="disabled ? '#' : props.url" class="flex items-center">
                    <span class="mr-2"><i :class="props.icon"></i></span>
                    <span :class="{'bg-yellow/75 rounded p-1': props.relevancy > 0.996}">{{ props.title }}</span>
                    <PrimeBadge class="ml-4" :pt:root:class="'!bg-' + props.colour">{{ props.type }}</PrimeBadge>
                </Link>

                <div>
                    <slot name="tags"></slot>
                </div>
            </div>
        </template>

        <template #subtitle v-if="props.subtitle">
            <div class="w-full flex justify-start">
                {{props.subtitle}}
            </div>
        </template>
        <template #content>
            <div v-if="!compact">
                <div class="grid" :class="{'grid-cols-2': $slots['bodyRight'], 'grid-cols-1': !$slots['bodyRight']}">
                    <div>
                        <slot name="body"></slot>
                    </div>
                    <div>
                        <slot name="bodyRight"></slot>
                    </div>
                </div>
            </div>

        </template>
        <template #footer>
            <div class="flex flex-row w-full justify-end items-center space-x-2">
                <Link :href="props.url">
                    <PrimeButton icon="fa fa-arrow-right" :disabled="disabled" severity="secondary" />
                </Link>
            </div>
        </template>
    </PrimeCard>
</template>

<style scoped>

</style>