<script lang="ts" setup>
import {useLayout} from './composables/layout';
import {Link, router} from '@inertiajs/vue3';
import UserMenu from './UserMenu.vue';
import {useEnvironment} from '@/composables/environment';
import AppTopNavigation from '@/PageLayout/AppTopNavigation.vue';
import Modal from '@/Components/Modal/Modal.vue';
import Search from './../../../../core/search/resources/js/InertiaComponents/Search.vue';
import {useToast} from 'primevue';
import {route} from 'ziggy-js';
import {useSettings} from '@/composables/settings';
import {computed, ref} from 'vue';
import {onKeyStroke} from '@vueuse/core';

const {onMenuToggle, toggleDarkMode, isDarkTheme} = useLayout();

const {isDeploying, version, environmentName} = useEnvironment();

// const toggleHelp = () => {
//     document.body.classList.toggle('body--show-help');
// };

const toast = useToast();

const settings = useSettings();

const isHidingSensitiveData = computed<boolean>(() => {
    return settings.settingsList.value['hide_sensitive_data'];
});

const toggleSensitiveData = () => {
    router.post(
        route('core.auth.sensitive-data'),
        {
            'hide_sensitive_data': !isHidingSensitiveData.value
        },
        {
            onSuccess: () => {
                toast.add({
                    severity: 'success',
                    summary: 'Sensitive data ' + (
                        isHidingSensitiveData.value ? 'hidden' : 'shown'
                    ),
                    life: 5000
                });
            },
            onError: () => {
                toast.add({
                    severity: 'error',
                    summary: 'Error ' + (
                        isHidingSensitiveData.value ? 'hiding' : 'showing'
                    ) + ' sensitive data',
                    life: 5000
                });
            }
        }
    );

};

onKeyStroke(
    ['s', 'S'],
    (e) => {
        useKeyShortcut(e, () => {
            isSearchVisible.value = true;
        });
    }
);

onKeyStroke(
    ['x', 'X'],
    (e) => {
        useKeyShortcut(e, () => {
            toggleSensitiveData();
        });
    }
);

const useKeyShortcut = (e: KeyboardEvent, callback: () => void) => {
    // Get element currently in focus
    const activeElement = document.activeElement as HTMLElement;
    // if active element is the body
    if (activeElement.tagName === 'BODY') {
        // focus the search input
        callback();
        e.preventDefault();
    }
};

const isSearchVisible = ref<boolean>(false);

const searchQuery = ref<string|null>(null);

</script>

<template>
    <div class="layout-topbar shadow-sm items-center flex justify-between">
        <div class="layout-topbar-logo-container">
            <button class="layout-menu-button layout-topbar-action" @click="onMenuToggle">
                <i class="fa fa-bars"></i>
            </button>
            <Link class="layout-topbar-logo" href="/">
                <PrimeImage alt="Swytch Logo" width="250">
                    <template #image>
                        <img v-if="isDarkTheme" alt="Swytch Logo" src="./../../../images/navbar-image-dark.png"
                             width="250"/>
                        <img v-else alt="Swytch Logo" src="./../../../images/navbar-image.png" width="250"/>
                    </template>
                </PrimeImage>

                <span>Tools</span>
            </Link>
        </div>

        <div class="invisible lg:visible">
            <app-top-navigation :full-width="false"></app-top-navigation>
        </div>

        <div class="layout-topbar-actions !ml-0">
            <div class="layout-config-menu items-center flex">

                <modal header="Search Swytch Tools" maximisable v-model="isSearchVisible">
                    <template #activator="{show}">
                        <PrimeButton variant="text" v-tooltip.left="'Search the whole of Swytch Tools (s)'" class="layout-topbar-action"
                                     @click="show">
                            <i class="fa fa-search"></i>
                        </PrimeButton>
                    </template>

                    <search :compact="true" v-model:query="searchQuery"></search>
                </modal>

                <PrimeBadge v-if="environmentName !== 'production'">{{ environmentName }}</PrimeBadge>
                <span v-help="'The current version of Swytch Tools'" class="flex items-center space-x-2">
                    <PrimeProgressSpinner v-if="isDeploying" :strokeWidth="8"
                                          pt:root:class="!h-6 !w-6 items-center"></PrimeProgressSpinner>
                    <span>{{ version }}</span>
                </span>

                <!--                <button class="layout-topbar-action show-help:text-[var(&#45;&#45;primary-color)]"  @click="toggleHelp">-->
                <!--                    <i class="fa fa-circle-question"></i>-->
                <!--                </button>-->

                <PrimeButton :severity="isHidingSensitiveData ? 'help' : null" variant="text" v-tooltip.left="isHidingSensitiveData ? 'Show sensitive data (x)' : 'Hide sensitive data (x)'" class="layout-topbar-action !m-0 !p-0"
                             @click="toggleSensitiveData">
                    <i class="fa fa-user-secret"></i>
                </PrimeButton>

                <Link :href="$route('swytch-tools.index')">
                    <PrimeButton variant="text" v-tooltip.left="'DevOps links'" class="layout-topbar-action !m-0 !p-0" >
                        <i class="fa fa-code"></i>
                    </PrimeButton>
                </Link>

                <PrimeButton variant="text" v-tooltip.left="'Toggle Dark Mode'" class="layout-topbar-action !m-0 !p-0"
                             @click="toggleDarkMode">
                    <i :class="['fa', { 'fa-moon': isDarkTheme, 'fa-sun': !isDarkTheme }]"></i>
                </PrimeButton>

                <Link :href="$route('core.configuration.index')">
                    <PrimeButton variant="text" v-tooltip.left="'Settings'" class="layout-topbar-action !m-0 !p-0" >
                        <i class="fa fa-cog"></i>
                    </PrimeButton>
                </Link>

                <user-menu></user-menu>
            </div>

        </div>
    </div>
</template>
