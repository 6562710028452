<script setup lang="ts">
import {SearchResultProps} from '../../types/search';
import SearchResultCard from '../SearchResultCard.vue';

const props = defineProps<SearchResultProps<{
    fulfilment_group: string;
    product_gen: string;
    order_count: number|null;
}>>();

</script>

<template>
    <!--
        :title="props.params.sku"
        :url="$route('tools.skus.sku.show', {sku: props.params.sku})"

-->
    <search-result-card
        :url="$route('core.fulfilment-groups.index', {fulfilment_group: props.params.fulfilment_group})"
        type="Fulfilment Group"
        :title="props.params.fulfilment_group"
        icon="fa fa-people-group"
        :relevancy="props.relevancy"
        colour="green"
        :compact="props.compact"
    >
        <template #tags>
            <PrimeBadge class="mr-2" severity="primary" v-if="props.params.order_count">
                {{ props.params.order_count }} orders
            </PrimeBadge>

            <PrimeBadge class="mr-2" severity="secondary" v-if="props.params.product_gen">
                {{ props.params.product_gen }}
            </PrimeBadge>
        </template>

        <template #body>
        </template>

    </search-result-card>
</template>

<style scoped>

</style>