<script setup lang="ts">

</script>

<template>
    <div class="flex flex-col space-y-2">
        <PrimeToolbar>
            <template #start>
                <slot name="toolbarStart"></slot>
            </template>

            <template #center>
                <slot name="toolbarCenter"></slot>
            </template>

            <template #end>
                <slot name="toolbarEnd"></slot>
            </template>
        </PrimeToolbar>

        <slot></slot>
    </div>
</template>

<style scoped>

</style>