<script setup lang="ts">
import {computed, onMounted, ref, shallowRef} from 'vue';
import {useToast} from 'primevue';
import axios, {AxiosResponse} from 'axios';
import {route} from 'ziggy-js';
import {SearchResult} from '../types/search';
import Order from './Results/Order.vue';
import Sku from './Results/Sku.vue';
import DashboardItem from './Results/DashboardItem.vue';
import Bike from './Results/Bike.vue';
import FulfilmentGroup from './Results/FulfilmentGroup.vue';
import Customer from './Results/Customer.vue';
import Dispatch from './Results/Dispatch.vue';
import DispatchRequest from './Results/DispatchRequest.vue';
import Returns from './Results/Returns.vue';
import ToolbarLayout from '@/Layouts/ToolbarLayout.vue';

const query = defineModel<string|null>('query');

const isSearching = ref<boolean>(false);

const toast = useToast();

const results = ref<SearchResult[]>([]);

const hasSearchedAtLeastOnce = ref<boolean>(false);

const props = withDefaults(defineProps<{
    compact?: boolean
}>(), {
    compact: false
});

const emit = defineEmits<{
    (event: 'searched', query: string|null): void;
}>();

onMounted(() => {
    console.log('mounted', query.value);
    if(query.value) {
        search();
    }
});

const search = () => {
    emit('searched', query.value ?? null);
    isSearching.value = true;
    axios.get(
        route('api.core.search.search', {
            query: query.value,
            includes: filterToTypes.value,
        })
    )
        .then((response: AxiosResponse<SearchResult[]>) => {
            results.value = response.data;
        })
        .catch(() => {
            toast.add({
                severity: 'error',
                summary: 'Search Error',
                detail: 'An error occurred while searching. Please try again.',
                life: 5000
            });
        })
        .then(() => {
            isSearching.value = false;
            hasSearchedAtLeastOnce.value = true;
        });
};

const resultComponentMap = shallowRef<Record<string, any>>({
    'order': Order,
    'skus': Sku,
    'dashboard_service': DashboardItem,
    'bikes': Bike,
    'fulfilment_group': FulfilmentGroup,
    'customer': Customer,
    'dispatch': Dispatch,
    'dispatch_request': DispatchRequest,
    'returns': Returns
});

const resultsWithComponents = computed<SearchResult & {
    component: any
}>(() => {
    return results.value.map((result: SearchResult) => {
        const component = resultComponentMap.value[result.type];

        if(!component) {
            toast.add({
                severity: 'error',
                summary: 'Search Error',
                detail: `No component found for search result type: ${result.type}. Please contact support.`,
                life: 5000
            });
        }

        return {
            ...result,
            component: component ?? 'div'
        };
    });
});

const filterMenu = ref(null);

const toggleFilterMenu = (event) => {
    filterMenu.value?.toggle(event);
};

const filterToTypes = ref<string[]>([
    'order',
    'customer',
    'dispatch',
    'dispatch_request',
    'returns',
    'skus',
    'dashboard_service',
    'fulfilment_group',
    'bikes'
]);

const filterOptions = ref<{ label: string, value: string }[]>([
    {
        label: 'Orders',
        value: 'order'
    },
    {
        label: 'Customers',
        value: 'customer'
    },
    {
        label: 'Dispatches',
        value: 'dispatch'
    },
    {
        label: 'Dispatch Requests',
        value: 'dispatch_request'
    },
    {
        label: 'Returns',
        value: 'returns'
    },
    {
        label: 'SKUs',
        value: 'skus'
    },
    {
        label: 'Dashboard Items',
        value: 'dashboard_service'
    },
    {
        label: 'Fulfilment Groups',
        value: 'fulfilment_group'
    },
    {
        label: 'Bikes',
        value: 'bikes'
    }
]);

</script>

<template>
    <toolbar-layout>
        <template #toolbarCenter>
            <form @submit.prevent="search" novalidate>
                <PrimeInputGroup>
                    <PrimeInputGroupAddon>
                        <PrimeButton icon="fa fa-filter" severity="secondary" variant="text" @click="toggleFilterMenu"
                                     :badge="filterToTypes.length === filterOptions.length ? null : filterToTypes.length"/>
                    </PrimeInputGroupAddon>
                    <PrimeInputText v-model="query" autofocus placeholder="Search" />
                    <PrimeInputGroupAddon>
                        <PrimeButton type="submit" :loading="isSearching" icon="fa fa-magnifying-glass" severity="secondary" variant="text" @click="search" />
                    </PrimeInputGroupAddon>
                </PrimeInputGroup>
                <PrimePopover ref="filterMenu" class="!min-w-fit">
                    <div class="flex flex-col gap-4">
                        <div>
                            <span class="font-medium block mb-2">Search across...</span>

                            <PrimeMultiSelect v-model="filterToTypes" :options="filterOptions" optionLabel="label" optionValue="value"
                                              placeholder="Select what to search"
                                              :maxSelectedLabels="3" class="w-full md:w-80" />
                        </div>
                    </div>
                </PrimePopover>
            </form>
        </template>

        <div>
            <div class="flex w-full text-center justify-center text-gray-500 text-lg mt-4" v-if="!isSearching && results.length === 0 && hasSearchedAtLeastOnce">
                <span>No results found</span>
            </div>
            <div class="flex flex-col space-y-2 mt-4" v-else-if="!isSearching">
                <div v-for="result in resultsWithComponents" :key="result.id">
                    <component :is="result.component" :compact="props.compact" :relevancy="result.relevancy" :params="result.params" />
                </div>
            </div>
            <div v-else class="flex w-full text-center">
                <PrimeProgressSpinner />
            </div>
        </div>
    </toolbar-layout>

<!--    <div class="w-full h-full justify-center items-center text-center">-->
<!--        <div class="w-fit ml-auto mr-auto">-->
<!---->
<!--        </div>-->

<!--    </div>-->
</template>

<style scoped>

</style>