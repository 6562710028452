<script setup lang="ts">
import {SearchResultProps} from '../../types/search';
import SearchResultCard from '../SearchResultCard.vue';
import {computed} from 'vue';
import {useDateTime} from '@/composables/datetime';
import StatusChip from '@/Components/Status/StatusChip.vue';

const props = defineProps<SearchResultProps<{
    id: number;
    internal_order_reference: string;
    source_created_at: string;
    parent_reference: string|null;
    customer_name: string;
    customer_email: string;
    category: string|null;
    generation: string|null;
    status: string;
    unified_status: string;
    skus: Array<{quantity: number, sku: string}>
}>>();

const {shortReadableDate} = useDateTime();

const subtitle = computed<string>(() => {
    let subtitle = 'Customer order placed on ' + shortReadableDate(props.params.source_created_at);

    if(props.params.parent_reference) {
        subtitle += ' against order ' + props.params.parent_reference;
    }

    subtitle += ' for ' + props.params.customer_name + ' (' + props.params.customer_email + ')';

    return subtitle;
});

</script>

<template>
    <search-result-card
        :subtitle="subtitle"
        :url="$route('core.orders.show', {order: props.params.id})"
        :relevancy="props.relevancy"
        type="Order"
        :title="props.params.internal_order_reference"
        icon="fa fa-receipt"
        colour="purple"
        :compact="props.compact"
    >
        <template #tags>
            <PrimeBadge class="mr-2" severity="secondary" v-if="props.params.category">
                {{ props.params.category }}
            </PrimeBadge>

            <PrimeBadge v-if="props.params.generation" class="mr-2">
                {{ props.params.generation }}
            </PrimeBadge>

            <StatusChip :status="props.params.status" :unified-status="props.params.unified_status"/>
        </template>

        <template #body>
            <div v-for="o in props.params.skus">
                {{o.quantity}} x {{o.sku}}
            </div>
        </template>

    </search-result-card>
</template>

<style scoped>

</style>