<script setup lang="ts">
import {SearchResultProps} from '../../types/search';
import SearchResultCard from '../SearchResultCard.vue';

const props = defineProps<SearchResultProps<{
    title: string;
    icon: string;
    description: string;
    url: string;
    category: string;
    visible: boolean;
}>>();

</script>

<template>
    <!--
        :title="props.params.sku"
        :url="$route('tools.skus.sku.show', {sku: props.params.sku})"

-->
    <search-result-card
        :subtitle="props.params.description"
        :url="props.params.url"
        type="Swytch Tools Page"
        :title="props.params.title"
        :icon="'fa fa-' + props.params.icon"
        :relevancy="props.relevancy"
        :disabled="!props.params.visible"
        colour="grey"
        :compact="props.compact"
    >
        <template #tags>
            <PrimeBadge class="mr-2" severity="secondary" v-if="props.params.category">
                {{ props.params.category }}
            </PrimeBadge>

            <!--            <status-chip :status="props.params.status"></status-chip>-->

            <!--            <PrimeBadge v-if="props.params.generation" class="mr-2">-->
            <!--                {{ props.params.generation }}-->
            <!--            </PrimeBadge>-->

            <!--            <StatusChip :status="props.params.status" :unified-status="props.params.unified_status"/>-->
        </template>

    </search-result-card>
</template>

<style scoped>

</style>