import {computed} from 'vue';
import {router, usePage} from '@inertiajs/vue3';

export function useSettings() {

    const page = usePage();

    const settingsList = computed<{[key: string]: any}>(() => page.props.settings);

    const refreshSettings = () => {
        router.reload();
    };

    return {settingsList, refreshSettings};
}