<script setup lang="ts">
import {SearchResultProps} from '../../types/search';
import SearchResultCard from '../SearchResultCard.vue';
import {computed} from 'vue';

const props = defineProps<SearchResultProps<{
    id: number;
    spokes_id: string;
    compatible: boolean;
    kit_type: string;
    incompatibility_reasons: string[];
    incompatibility_reason_other: string;
}>>();

const subtitle = computed(() => {
    if(props.params.compatible) {
        let subtitle = 'This bike is compatible';

        if(props.params.kit_type) {
            subtitle += ' with a ' + props.params.kit_type + ' kit';
        }

        return subtitle;
    } else if(props.params.compatible === false) {
        let incompatibleReasons = props.params.incompatibility_reasons;

        if(props.params.incompatibility_reason_other) {
            incompatibleReasons.push(props.params.incompatibility_reason_other);
        }

        return  'This bike is not compatible due to the following reasons: ' + incompatibleReasons.join(', ');
    }

    return 'Compatibility not yet assessed';
});

</script>

<template>
    <search-result-card
        :subtitle="subtitle"
        :url="$route('tools.wifmb.auto-assessment-bikes.edit', {auto_assessment_bike: props.params.id})"
        type="Bike"
        :title="props.params.spokes_id"
        icon="fa fa-bicycle"
        :relevancy="props.relevancy"
        colour="pink"
        :compact="props.compact"
    >
        <template #tags>
            <PrimeBadge class="mr-2" severity="success" v-if="props.params.compatible">Compatible</PrimeBadge>
            <PrimeBadge class="mr-2" severity="danger" v-if="props.params.compatible === false">Not Compatible</PrimeBadge>
            <PrimeBadge class="mr-2" severity="secondary" v-else>Unassessed</PrimeBadge>

        </template>

        <template #body>
        </template>

    </search-result-card>
</template>

<style scoped>

</style>