<script setup lang="ts">

import {useFavouriteItem} from '../../../../../tools/dashboard/resources/js/UseFavouriteItem';
import {computed} from 'vue';
import {useSettings} from '@/composables/settings';

const {favouriteItem, unfavouriteItem} = useFavouriteItem();

const {settingsList, refreshSettings} = useSettings();

const deselect = () => {
    unfavouriteItem(props.itemId);
    wasChanged();
};

const select = () => {
    favouriteItem(props.itemId);
    wasChanged();
};

const wasChanged = () => {
    refreshSettings();
};

const isFavourited = computed(() => {
    return settingsList.value.favourited_items.includes(props.itemId);
});

const props = defineProps<{
    item: any;
    itemId: string;
}>();

const toggleFavourite = () => {
    if(isFavourited.value === true) {
        deselect();
    } else {
        select();
    }
};

</script>

<template>
    <a v-ripple class="flex align-items-center" @click="toggleFavourite">
        <i :class="{'fa fa-heart': true, 'text-pink': isFavourited}" />
        <span class="ml-2">
            <span v-if="isFavourited">Unfavourite</span>
            <span v-else>Favourite</span>
        </span>
    </a>
</template>

<style scoped>

</style>