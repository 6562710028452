import moment from 'moment';

export function useDateTime() {

    const asMoment = (date: string) => {
        return moment(date);
    };

    const fromNow = (date: string, allowFullDate: boolean = true) => {
        // If date is more than 11 months in the past or future, return the result of 'fullDate' instead
        if (allowFullDate && Math.abs(asMoment(date).diff(moment(), 'months')) > 11) {
            return fullDate(date);
        }

        return asMoment(date).fromNow();
    };

    const formatDate = (date: string, format: string) => {
        return asMoment(date).format(format);
    };

    const fullDate = (date: string) => {
        return asMoment(date).format('MMMM Do YYYY, h:mm:ss a');
    };

    const shortDate = (date: string) => {
        return asMoment(date).format('DD/MM/yyyy');
    };

    const shortReadableDate = (date: string) => {
        return asMoment(date).format('MMMM Do YYYY');
    };

    const shortDateTime = (date: string) => {
        return asMoment(date).format('DD/MM/yyyy HH:mm:ss');
    };

    // Return the date in the format 'Mon Dec 02 2024 00:00:00 GMT+0000 (Greenwich Mean Time)'
    const asDate = (date: string|Date): Date|null => {
        if(date instanceof Date) {
            return date;
        }

        const d = asMoment(date);
        if(d.isValid()) {
            return d.toDate();
        }
        return null;
    };

    const getDiffInDays = (date1: string, date2: string) => {
        const a = moment(date1);
        const b = moment(date2);
        return a.diff(b, 'days');
    };

    return {
        fromNow,
        fullDate,
        shortDate,
        shortDateTime,
        getDiffInDays,
        shortReadableDate,
        asDate,
        formatDate
    };

}