<script setup lang="ts">
import {computed, ref} from 'vue';

const props = withDefaults(defineProps<{
    header: string;
    subtitle?: string|null;
    modelValue?: boolean | null;
    maximisable?: boolean;
}>(), {
    subtitle: null,
    modelValue: null,
    maximisable: false,
});

const emit = defineEmits<{
    (event: 'update:modelValue', value: boolean): void;
}>();

const shouldShow = computed<boolean>({
    get() {
        if(props.modelValue === null) {
            return overriddenShouldShow.value;
        } else {
            return props.modelValue;
        }
    },
    set(value) {
        overriddenShouldShow.value = value;
        emit('update:modelValue', value);
    }
});

const overriddenShouldShow = ref<boolean>(false);

const show = () => {
    shouldShow.value = true;
};

const hide = () => {
    shouldShow.value = false;
};

</script>

<template>


    <span>
        <slot name="activator" :show="show">

        </slot>
    </span>

    <PrimeDialog :maximizable="props.maximisable" v-model:visible="shouldShow" :header="props.header" modal  :style="{ width: '75vw' }" :breakpoints="{ '1199px': '85vw', '575px': '95vw' }">
        <span class="text-surface-500 dark:text-surface-400 block mb-8" v-if="props.subtitle">
            {{props.subtitle}}
        </span>
        <slot :hide="hide">

        </slot>

        <div class="flex justify-end gap-2 space-x-1">
            <slot name="footer" :hide="hide">

            </slot>
        </div>
    </PrimeDialog>

</template>

<style scoped>

</style>