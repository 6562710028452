<script setup lang="ts">
import {useStatusColours} from '@/composables/statusColours';
import {computed} from 'vue';

const props = withDefaults(defineProps<{
    status?: string|null,
    label?: string|null,
    unifiedStatus?: string|null
}>(), {
    status: 'No Status Found',
    label: null,
    unifiedStatus: null
});

const {getStatusColour} = useStatusColours();

const statusColour = computed(() => getStatusColour(props.status));

const humanReadableStatus = computed<string>(() => {
    if(props.label) {
        return props.label;
    }

    if (props.status === null || props.status === undefined) {
        return props.status;
    }

    return props.status.split('_').map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ');
});

</script>

<template>
    <PrimeBadge v-tooltip.bottom="props.unifiedStatus" :class="'!bg-' + statusColour">
        {{humanReadableStatus}}
    </PrimeBadge>
</template>

<style scoped>

</style>