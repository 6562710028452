import {computed} from 'vue';
import {usePage} from '@inertiajs/vue3';

export function useEnvironment() {

    const page = usePage();

    const environmentName = computed(() => {
        return page.props.environment.name;
    });

    const isProduction = computed(() => {
        return page.props.environment.isProduction;
    });

    const isDeploying = computed<boolean>(() => {
        return page.props.environment.isDeploying;
    });

    const version = computed<string>(() => {
        return page.props.environment.version;
    });

    return {environmentName, isProduction, isDeploying, version};
}
