<script setup lang="ts">
import {SearchResultProps} from '../../types/search';
import SearchResultCard from '../SearchResultCard.vue';
import {computed} from 'vue';
import StatusChip from '@/Components/Status/StatusChip.vue';

const props = defineProps<SearchResultProps<{
    id: number;
    sku: string;
    category: string;
    description: string;
    status: string;
}>>();

const subtitle = computed<string>(() => {
    return props.params.description;
});

</script>

<template>
    <search-result-card
        :subtitle="subtitle"
        :url="$route('tools.skus.sku.show', {sku: props.params.sku})"
        type="Product"
        :relevancy="props.relevancy"
        :title="props.params.sku"
        icon="fa fa-car-battery"
        colour="yellow"
        :compact="props.compact"
    >
        <template #tags>
            <PrimeBadge class="mr-2" severity="secondary" v-if="props.params.category">
                {{ props.params.category }}
            </PrimeBadge>

            <status-chip :status="props.params.status"></status-chip>

            <!--            <PrimeBadge v-if="props.params.generation" class="mr-2">-->
            <!--                {{ props.params.generation }}-->
            <!--            </PrimeBadge>-->

            <!--            <StatusChip :status="props.params.status" :unified-status="props.params.unified_status"/>-->
        </template>

        <template #body>
            <!--            <div v-for="o in props.params.skus">-->
            <!--                {{o.quantity}} x {{o.sku}}-->
            <!--            </div>-->
        </template>

    </search-result-card>
</template>

<style scoped>

</style>